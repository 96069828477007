import { useEffect, useState } from "react";
import { Row } from "../row_column_type/row_col";
import { Button } from "@mui/material";
import { getSelectedModifiedRows, findChangesWithDiff } from "./shared";
import PreviewChangesModal from "./preview_changes_modal";

interface SubmitChangesButtonProps {
    buttonText: string;
    initialRows: Row[];
    editedRows: Row[];
    selectedRows: Set<string>;
    onSubmit: (changes: any[]) => void;
    command: string;
    clearCommand: () => void;
    propertiesToCheck: Set<string>;
    comparisonKey: string;
    ignoreMsUpdates?: boolean;
    useTransaction?: boolean;
    submitAllSelectedRecords?: boolean;
}

const getChanges = (
    submitAllSelectedRecords: boolean,
    initialRows: Row[],
    editedRows: Row[],
    selectedRows: Set<string>,
    propertiesToCheck: Set<string>,
    comparisonKey: string
) => {
    if (submitAllSelectedRecords) {
        return getSelectedModifiedRows(
            editedRows,
            selectedRows,
            propertiesToCheck,
            comparisonKey
        );
    } else {
        return findChangesWithDiff(
            initialRows,
            editedRows,
            selectedRows,
            propertiesToCheck,
            comparisonKey
        );
    }
};

const SubmitChangesButton: React.FC<SubmitChangesButtonProps> = ({
    buttonText,
    initialRows,
    editedRows,
    selectedRows,
    onSubmit,
    command,
    clearCommand,
    propertiesToCheck,
    comparisonKey = "market_ticker",
    ignoreMsUpdates = false,
    useTransaction = false,
    submitAllSelectedRecords = false,
}) => {
    const [open, setOpen] = useState(false);
    const [changes, setChanges] = useState<any[]>([]);

    useEffect(() => {
        const records = getChanges(
            submitAllSelectedRecords,
            initialRows,
            editedRows,
            selectedRows,
            propertiesToCheck,
            comparisonKey
        );
        setChanges(records);
    }, [initialRows, editedRows, selectedRows, propertiesToCheck, comparisonKey, submitAllSelectedRecords]);

    const handleOpen = () => {
        const records = getChanges(
            submitAllSelectedRecords,
            initialRows,
            editedRows,
            selectedRows,
            propertiesToCheck,
            comparisonKey
        );
        setChanges(records);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = () => {
        setOpen(false);
        onSubmit(changes);
    };

    useEffect(() => {
        if (command === 'preview_submit' || command === 'p_s') {
            handleOpen();
            clearCommand();
        }
    }, [command, clearCommand]);

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                onClick={handleOpen}
                disabled={selectedRows.size === 0}
                style={{ marginTop: '20px' }}
                fullWidth
            >
                {buttonText}
            </Button>
            <PreviewChangesModal
                open={open}
                initialData={initialRows}
                editedData={editedRows}
                selectedRows={selectedRows}
                onClose={handleClose}
                onConfirm={handleConfirm}
                propertiesToCheck={propertiesToCheck}
                changes={changes}
                comparisonKey={comparisonKey}
                ignoreMsUpdates={ignoreMsUpdates}
                useTransaction={useTransaction}
            />
        </>
    );
};


export default SubmitChangesButton;