import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Typography,
    CircularProgress,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    TableSortLabel,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    Switch,
    FormControlLabel,
    TablePagination,
} from '@mui/material';
import { TaskAlt } from '@mui/icons-material';

interface TraderAction {
    action_id: number;
    trader: string | null;
    secondary_traders: string[] | null;
    market_tickers: string[] | null;
    event_tickers: string[] | null;
    action_description: string;
    action_timestamp: string | null;
}

const TraderActionDashboard: React.FC = () => {
    const [actions, setActions] = useState<TraderAction[]>([]);
    const [seenActionIds, setSeenActionIds] = useState<Set<number>>(new Set());
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [filterTrader, setFilterTrader] = useState<string>('');
    const [sortConfig, setSortConfig] = useState<{ key: keyof TraderAction; direction: 'asc' | 'desc' } | null>(null);
    const [modalContent, setModalContent] = useState<string | null>(null);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [autoRefetch, setAutoRefetch] = useState<boolean>(true);

    const [notificationsEnabled, setNotificationsEnabled] = useState<boolean>(
        JSON.parse(localStorage.getItem('notificationsEnabled') || 'true')
    );

    const [soundEnabled, setSoundEnabled] = useState<boolean>(
        JSON.parse(localStorage.getItem('soundEnabled') || 'true')
    );

    const [initialLoad, setInitialLoad] = useState<boolean>(true);

    // Pagination state
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(50);

    const baseUrl = process.env.REACT_APP_API_BASE_URL;

    const saveToggleState = (key: string, value: boolean) => {
        localStorage.setItem(key, JSON.stringify(value));
    };

    const requestNotificationPermission = useCallback(() => {
        if (Notification.permission === 'default') {
            Notification.requestPermission();
        }
    }, []);

    const sendNotification = useCallback(
        (title: string, body: string) => {
            if (notificationsEnabled && Notification.permission === 'granted') {
                new Notification(title, { body });
            }
        },
        [notificationsEnabled, soundEnabled]
    );

    const seenActionIdsRef = useRef(seenActionIds);
    const initialLoadRef = useRef(initialLoad);

    useEffect(() => {
        seenActionIdsRef.current = seenActionIds;
    }, [seenActionIds]);

    useEffect(() => {
        initialLoadRef.current = initialLoad;
    }, [initialLoad]);

    const loadActions = useCallback(async () => {
        setLoading(true);
        setError(null);

        const token = localStorage.getItem('authToken');
        if (!token) {
            throw new Error('You must be logged in to submit changes.');
        }

        const url = filterTrader
            ? `${baseUrl}/trader-actions/get-actions?trader=${encodeURIComponent(filterTrader)}`
            : `${baseUrl}/trader-actions/get-actions`;

        try {
            const response = await axios.get(url, {
                headers: { Authorization: `Bearer ${token}` },
            });

            const actionsResp = response.data;
            if (!actionsResp || actionsResp.length === 0) {
                setActions([]);
                return;
            }

            // Use refs to access the latest state
            // Otherwise the useEffect auto reload closure doesn't use the synced state.
            const unseenActions = actionsResp.filter((action: TraderAction) => !seenActionIdsRef.current.has(action.action_id));
            if (!initialLoadRef.current && unseenActions.length > 0) {
                unseenActions.forEach((action: TraderAction) => {
                    sendNotification(
                        'New Alert',
                        `Trader: ${action.trader || 'no trader'} - ${action.action_description}`
                    );
                });

                if (soundEnabled) {
                    try {
                        const audio = new Audio('/notification-sound.mp3');
                        await audio.play();
                    } catch (err) {
                        console.error('Failed to play notification sound:', err);
                    }
                }
            }

            // Update the seenActionIds ref
            const updatedSeenActionIds = new Set(seenActionIdsRef.current);
            actionsResp.forEach((action: TraderAction) => updatedSeenActionIds.add(action.action_id));
            setSeenActionIds(updatedSeenActionIds);

            setActions(actionsResp);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An error occurred');
        } finally {
            setLoading(false);
            if (initialLoadRef.current) {
                setInitialLoad(false);
            }
        }
    }, [filterTrader, sendNotification, soundEnabled, baseUrl]);

    const handleDelete = async (actionId: number) => {
        const token = localStorage.getItem('authToken');
        if (!token) {
            throw new Error('You must be logged in to submit changes.');
        }

        const url = `${baseUrl}/trader-actions/delete-action/${actionId}`;

        try {
            await axios.delete(url, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setActions((prev) => prev.filter((action) => action.action_id !== actionId));
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Failed to delete the action');
        }
    };

    const handleSort = (key: keyof TraderAction) => {
        const direction = sortConfig?.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
        setSortConfig({ key, direction });

        setActions((prev) =>
            [...prev].sort((a, b) => {
                const aValue = a[key] ?? '';
                const bValue = b[key] ?? '';
                if (aValue < bValue) return direction === 'asc' ? -1 : 1;
                if (aValue > bValue) return direction === 'asc' ? 1 : -1;
                return 0;
            })
        );
    };

    const handleOpenModal = (content: string | string[] | null) => {
        if (!content || (Array.isArray(content) && content.length === 0)) return;
        const contentString = Array.isArray(content) ? content.join(', ') : content;
        setModalContent(contentString);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setModalContent(null);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to the first page when rows per page change
    };

    const formatAsCodeBlock = (content: string | string[] | null): React.ReactNode => {
        if (!content || (Array.isArray(content) && content.length === 0)) return 'N/A';

        const contentString = Array.isArray(content) ? content.join(', ') : content;

        return (
            <div
                style={{
                    fontFamily: 'monospace',
                    fontSize: '0.7rem',
                    backgroundColor: '#f5f5f5',
                    border: '1px solid #ccc',
                    padding: '5px',
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '200px',
                    maxHeight: '100px',
                    cursor: 'pointer',
                    overflowY: 'auto',
                }}
                onClick={() => handleOpenModal(content)}
                title="Click to expand"
            >
                <code>{contentString}</code>
            </div>
        );
    };

    useEffect(() => {
        requestNotificationPermission();
        loadActions();
        let interval: NodeJS.Timeout | undefined;
        if (autoRefetch) {
            interval = setInterval(loadActions, 5000);
        }
        return () => {
            if (interval) clearInterval(interval);
        };
    }, [autoRefetch, filterTrader]);

    return (
        <div style={{ padding: '10px' }}>
            <Typography variant="h6" gutterBottom>
                Trader Action Dashboard
            </Typography>
            <FormControl fullWidth margin="dense" sx={{ fontSize: '0.8rem' }}>
                <InputLabel sx={{ fontSize: '0.8rem' }}>Filter by Trader</InputLabel>
                <Select
                    value={filterTrader}
                    onChange={(e) => setFilterTrader(e.target.value)}
                    sx={{ fontSize: '0.8rem', height: '30px' }}
                >
                    <MenuItem value="">none</MenuItem>
                    <MenuItem value="adhi">adhi</MenuItem>
                    <MenuItem value="jason">jason</MenuItem>
                    <MenuItem value="luis">luis</MenuItem>
                    <MenuItem value="richard">richard</MenuItem>
                    <MenuItem value="zengyin">zengyin</MenuItem>
                </Select>
            </FormControl>

            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '10px' }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={loadActions}
                    sx={{ fontSize: '0.8rem', height: '30px' }}
                >
                    Refresh
                </Button>
                <Button
                    variant="contained"
                    color={autoRefetch ? 'primary' : 'secondary'}
                    onClick={() => setAutoRefetch((prev) => !prev)}
                    sx={{ fontSize: '0.8rem', height: '30px' }}
                >
                    {autoRefetch ? 'Stop Auto Refresh' : 'Start Auto Refresh'}
                </Button>
                <FormControlLabel
                    control={
                        <Switch
                            checked={notificationsEnabled}
                            onChange={() => {
                                const newValue = !notificationsEnabled;
                                setNotificationsEnabled(newValue);
                                saveToggleState('notificationsEnabled', newValue);
                            }}
                        />
                    }
                    label="Enable Notifications"
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={soundEnabled}
                            onChange={() => {
                                const newValue = !soundEnabled;
                                setSoundEnabled(newValue);
                                saveToggleState('soundEnabled', newValue);
                            }}
                        />
                    }
                    label="Enable Sound"
                />
                {loading && <CircularProgress size={20} />}
                {error && <Typography color="error" sx={{ fontSize: '0.8rem' }}>{error}</Typography>}
            </div>

            <TableContainer component={Paper} sx={{ marginTop: '10px' }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {[
                                { label: 'Action ID', key: 'action_id' },
                                { label: 'Trader(s)', key: 'trader' },
                                { label: 'Market Tickers', key: 'market_tickers' },
                                { label: 'Event Tickers', key: 'event_tickers' },
                                { label: 'Action Description', key: 'action_description' },
                                { label: 'Action Timestamp', key: 'action_timestamp' },
                            ].map((column) => (
                                <TableCell key={column.key} sx={{ fontWeight: 'bold', fontSize: '0.8rem' }}>
                                    <TableSortLabel
                                        active={sortConfig?.key === column.key}
                                        direction={sortConfig?.key === column.key ? sortConfig.direction : 'asc'}
                                        onClick={() => handleSort(column.key as keyof TraderAction)}
                                    >
                                        {column.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                            <TableCell sx={{ fontWeight: 'bold', fontSize: '0.8rem' }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {actions.length > 0 ? (
                            actions
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) // Paginate the rows
                                .map((action) => (
                                    <TableRow key={action.action_id}>
                                        <TableCell sx={{ fontSize: '0.8rem' }}>{action.action_id}</TableCell>
                                        <TableCell sx={{ fontSize: '0.8rem' }}>{action.trader}</TableCell>
                                        <TableCell sx={{ fontSize: '0.8rem' }}>
                                            {formatAsCodeBlock(action.market_tickers)}
                                        </TableCell>
                                        <TableCell sx={{ fontSize: '0.8rem' }}>
                                            {formatAsCodeBlock(action.event_tickers)}
                                        </TableCell>
                                        <TableCell sx={{ fontSize: '0.8rem' }}>
                                            {formatAsCodeBlock(action.action_description)}
                                        </TableCell>
                                        <TableCell sx={{ fontSize: '0.8rem' }}>
                                            {action.action_timestamp
                                                ? new Date(action.action_timestamp).toLocaleString()
                                                : 'N/A'}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                color="success"
                                                onClick={() => handleDelete(action.action_id)}
                                                title="Mark as Complete"
                                            >
                                                <TaskAlt />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={7} align="center" sx={{ fontSize: '0.8rem' }}>
                                    No actions available
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={actions.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <Dialog open={modalOpen} onClose={handleCloseModal} maxWidth="md" fullWidth>
                <DialogTitle>Full Content</DialogTitle>
                <DialogContent>
                    <pre
                        style={{
                            fontFamily: 'monospace',
                            whiteSpace: 'pre-wrap',
                            fontSize: '0.8rem',
                            backgroundColor: '#f5f5f5',
                            border: '1px solid #ccc',
                            padding: '10px',
                            overflowX: 'auto',
                        }}
                    >
                        {modalContent}
                    </pre>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default TraderActionDashboard;
