import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './login';
import MarketControlDashboard from './market_control_dashboard';
import Navbar from './navbar';
import PrivateRoute from './private_route';
import TradesViewerContainer from './trade_scoller/trade_scoller';
import PolymarketTradesViewer from './trade_scoller/polymarket_trade_scroller';
import RulesList from './mm_rules_list';
import MarketTable from './fed_dashboard';
import MarketOrderInfoTable from './market_and_order_info';
import TraderActionDashboard from './trader_action_dashboard';

const App: React.FC = () => {
  const [token, setToken] = useState<string | null>(localStorage.getItem('authToken'));

  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Navigate to="/market-data" />} />
          <Route element={<PrivateRoute />}>
            <Route path="/market-data" element={<MarketControlDashboard token={token} />} />
            <Route path="/market-dash" element={<MarketControlDashboard token={token} />} />
            <Route path="/market-data-elections" element={<MarketControlDashboard token={token} electionMode={true} />} />
            <Route path="/market-dash-elections" element={<MarketControlDashboard token={token} electionMode={true} />} />
            <Route path="/fed-dashboard" element={<MarketTable token={token} />} />
            <Route path="/trades" element={<TradesViewerContainer />} />
            <Route path="/polymarket-trades" element={<PolymarketTradesViewer />} />
            <Route path="/rules-list" element={<RulesList />} />
            <Route path="/market-and-order-info" element={<MarketOrderInfoTable />} />
            <Route path="/trader-action-dashboard" element={<TraderActionDashboard />} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
};

export default App;