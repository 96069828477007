import React, { useState, useEffect } from 'react';
import {
    CircularProgress,
    Alert,
    Box,
    Grid,
    TextField,
    Button,
    Backdrop,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Checkbox,
    FormControlLabel
} from '@mui/material';
import axios from 'axios';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { SelectChangeEvent } from '@mui/material';
import FullFeaturedCrudGrid from './market_grid';

interface Filters {
    tickerFilter: string;
    tradingStatusFilter: string;
    peMinInput: string;
    peMaxInput: string;
    maxRows: string,
    traderFilter: string;
    marketTagFilter: string;
}

interface Preset {
    name: string;
}

interface MarketDataGridProps {
    token: string | null;
    electionMode?: boolean;
}

const MarketControlDashboard: React.FC<MarketDataGridProps> = ({ token, electionMode }) => {
    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [filters, setFilters] = useState<Filters>({
        tickerFilter: '',
        tradingStatusFilter: 'all',
        peMinInput: '0',
        peMaxInput: '100',
        maxRows: '200',
        traderFilter: '',
        marketTagFilter: '',
    });
    const [presets, setPresets] = useState<Preset[]>([]);
    const [selectedPreset, setSelectedPreset] = useState<string | null>(null);

    const [tickerInput, setTickerInput] = useState(filters.tickerFilter);
    const [tradingStatusInput, setTradingStatusInput] = useState(filters.tradingStatusFilter);
    const [peMinInput, setPeMinInput] = useState(filters.peMinInput);
    const [peMaxInput, setPeMaxInput] = useState(filters.peMaxInput);
    const [traderFilterInput, setTraderFilterInput] = useState(filters.traderFilter)
    const [marketTagFilterInput, setMarketTagFilterInput] = useState(filters.marketTagFilter)
    const [maxRowsInput, setMaxRowsInput] = useState(filters.maxRows);
    const [shouldGetLastUpdateInfo, setShouldGetLastUpdateInfo] = useState(false);
    const [getRestingOrderInfo, setShouldGetRestingOrderInfo] = useState(false);
    const [shouldShowRestingOrders, setShouldshouldShowRestingOrders] = useState(false);

    useEffect(() => {
        const savedPresets = JSON.parse(localStorage.getItem('filterPresets') || '[]');
        setPresets(savedPresets);
    }, []);

    useEffect(() => {
        if (selectedPreset) {
            setFilters((prevFilters) => ({
                ...prevFilters,
                tickerFilter: selectedPreset,
            }));
        }
    }, [selectedPreset]);

    useEffect(() => {
        fetchData();
    }, [filters]);


    const fetchData = async () => {
        try {
            setLoading(true);

            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            const queryParams = new URLSearchParams({
                ...filters,
                getLastUpdateInfo: shouldGetLastUpdateInfo ? 'true' : 'false',
                getPolymarketData: electionMode ? 'true' : 'false'
            }).toString();
            const response = await axios.get(`${baseUrl}/market-data?${queryParams}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setData(response.data);

            const baseUrlKlear = process.env.REACT_APP_API_BASE_URL_KLEAR_BACKEND;

            setShouldshouldShowRestingOrders(false);
            if (getRestingOrderInfo) {
                let filterQuery = '';
                if (filters.tickerFilter) {
                    filterQuery = `prefix=${encodeURIComponent(filters.tickerFilter)}`;
                }

                const url = `${baseUrlKlear}/get-market-and-order-info?${filterQuery}`;

                const getMarketAndOrderInfoResponse = await axios.get(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });

                let marketAndInfoData = getMarketAndOrderInfoResponse.data;
                let initialData = response.data;
                if (getMarketAndOrderInfoResponse.data) {
                    const rows = initialData.map((row: any) => ({
                        ...row,
                        actual_spread: marketAndInfoData[row.market_ticker]?.actual_spread || 0,
                        expected_yes_orders: marketAndInfoData[row.market_ticker]?.expected_yes_orders || {},
                        expected_no_orders: marketAndInfoData[row.market_ticker]?.expected_no_orders || {},
                        resting_yes_orders: marketAndInfoData[row.market_ticker]?.resting_yes_orders || {},
                        resting_no_orders: marketAndInfoData[row.market_ticker]?.resting_no_orders || {}
                    }));

                    setShouldshouldShowRestingOrders(true);
                    setData(rows);
                }
            }
        } catch (e) {
            setError('Failed to fetch data. Please try again later.');
            console.error('Fetch error:', e);
        } finally {
            setLoading(false);
        }
    };

    const handleFilterChange = (key: keyof Filters, value: string) => {
        setFilters((prevFilters) => ({ ...prevFilters, [key]: value }));
        console.log(filters)
    };

    const handleAllFilterChange = (updatedValues: Partial<Filters>) => {
        setFilters((prevFilters) => ({ ...prevFilters, ...updatedValues }));
    };

    const updateAllFilterValues = () => {
        handleAllFilterChange({
            tickerFilter: tickerInput,
            tradingStatusFilter: tradingStatusInput,
            peMinInput: peMinInput,
            peMaxInput: peMaxInput,
            maxRows: maxRowsInput,
            traderFilter: traderFilterInput,
            marketTagFilter: marketTagFilterInput,
        });
    };

    const handlePresetSave = () => {
        const presetName = filters.tickerFilter;
        if (presetName) {
            const newPresets = [...presets, { name: presetName }];
            setPresets(newPresets);
            localStorage.setItem('filterPresets', JSON.stringify(newPresets));
        }
    };

    const handlePresetSelect = (event: SelectChangeEvent<string>) => {
        const selected = event.target.value as string;
        setSelectedPreset(selected);
    };

    const handlePresetDelete = () => {
        if (selectedPreset) {
            const newPresets = presets.filter((p) => p.name !== selectedPreset);
            setPresets(newPresets);
            localStorage.setItem('filterPresets', JSON.stringify(newPresets));
            setSelectedPreset(null);
        }
    };

    return (
        <Box sx={{ mx: 2, my: 4 }}>
            <Backdrop open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {error && <Alert severity="error">{error}</Alert>}
            <Box>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={3}>
                        <TextField
                            label="Ticker Filter"
                            value={tickerInput}
                            onChange={(e) => setTickerInput(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    updateAllFilterValues();
                                }
                            }}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel>Trader Filter</InputLabel>
                            <Select
                                value={traderFilterInput}
                                onChange={(e) => {
                                    setTraderFilterInput(e.target.value)
                                    handleFilterChange('traderFilter', e.target.value)
                                }}
                            >
                                <MenuItem key={"none"} value={""}>
                                    none
                                </MenuItem>
                                <MenuItem key={"adhi"} value={"adhi"}>
                                    adhi
                                </MenuItem>
                                <MenuItem key={"jason"} value={"jason"}>
                                    jason
                                </MenuItem>
                                <MenuItem key={"luis"} value={"luis"}>
                                    luis
                                </MenuItem>
                                <MenuItem key={"richard"} value={"richard"}>
                                    richard
                                </MenuItem>
                                <MenuItem key={"zengyin"} value={"zengyin"}>
                                    zengyin
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel>Select Preset</InputLabel>
                            <Select
                                value={selectedPreset || ''}
                                onChange={handlePresetSelect}
                            >
                                {presets.map((preset) => (
                                    <MenuItem key={preset.name} value={preset.name}>
                                        {preset.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            onClick={handlePresetSave}
                            variant="contained"
                            color="secondary"
                            startIcon={<SaveIcon />}
                            fullWidth
                            size="small"
                        >
                            Save Preset
                        </Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            onClick={handlePresetDelete}
                            variant="contained"
                            color="error"
                            startIcon={<DeleteIcon />}
                            fullWidth
                            size="small"
                            disabled={!selectedPreset}
                        >
                            Delete Preset
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Trading Status"
                            value={tradingStatusInput}
                            onChange={(e) => setTradingStatusInput(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    updateAllFilterValues();
                                }
                            }}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="PE Min"
                            value={peMinInput}
                            onChange={(e) => setPeMinInput(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    updateAllFilterValues();
                                }
                            }}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="PE Max"
                            value={peMaxInput}
                            onChange={(e) => setPeMaxInput(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    updateAllFilterValues();
                                }
                            }}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Max Rows"
                            value={maxRowsInput}
                            onChange={(e) => setMaxRowsInput(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    updateAllFilterValues();
                                }
                            }}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Market Tag Filter"
                            value={marketTagFilterInput}
                            onChange={(e) => setMarketTagFilterInput(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    updateAllFilterValues();
                                }
                            }}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={shouldGetLastUpdateInfo}
                                    onChange={(e) => setShouldGetLastUpdateInfo(e.target.checked)}
                                />
                            }
                            label="Get Last Update Info"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={getRestingOrderInfo}
                                    onChange={(e) => setShouldGetRestingOrderInfo(e.target.checked)}
                                />
                            }
                            label="Get Resting Order Related Info"
                        />
                    </Grid>
                </Grid>
                <Button
                    onClick={fetchData}
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="small"
                    sx={{ mt: 2 }}
                >
                    Re-query Data
                </Button>
            </Box>
            <Box sx={{ mt: 4, position: 'relative' }}>
                <Backdrop
                    sx={{
                        position: 'absolute',
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                        color: '#fff',
                    }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                {error ? (
                    <Alert severity="error">{error}</Alert>
                ) : (
                    <FullFeaturedCrudGrid
                        initialData={data}
                        command={''}
                        clearCommand={() => { }}
                        // maybe we should pass in the expected columns?
                        // make the grid more agnostic?
                        electionMode={electionMode}
                        shouldShowRestingOrders={shouldShowRestingOrders}
                    />
                )}
            </Box>
        </Box>
    );
};

export default MarketControlDashboard;
