import React, { useState, useEffect } from "react";
import axios from "axios";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Box, Grid, IconButton } from "@mui/material";

const TradesViewer = ({ instanceId }) => {
    const [trades, setTrades] = useState([]);
    const [isAutoRefresh, setIsAutoRefresh] = useState(true);
    const [tickerFilter, setTickerFilter] = useState("");
    const [traderFilter, setTraderFilter] = useState("");
    const [maxTrades, setMaxTrades] = useState(1000);
    const [status, setStatus] = useState("Active");
    const refreshInterval = 5000;

    useEffect(() => {
        fetchTrades();
        if (isAutoRefresh) {
            const intervalId = setInterval(fetchTrades, refreshInterval);
            return () => clearInterval(intervalId);
        }
    }, [isAutoRefresh, tickerFilter, traderFilter, maxTrades]);

    const fetchTrades = async () => {
        try {
            const token = localStorage.getItem("authToken");
            if (!token) {
                setStatus("Auth Error");
                return;
            }

            let url = `${process.env.REACT_APP_API_BASE_URL}/trades?limit=${maxTrades}`;
            if (tickerFilter) {
                url += `&ticker=${tickerFilter}`;
            }
            if (traderFilter) {
                url += `&trader=${traderFilter}`;
            }

            const response = await axios.get(url, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setTrades(groupAndSortTrades(response.data));
            setStatus("Active");
        } catch (error) {
            console.error("Error fetching trades:", error);
            setStatus("Error");
        }
    };

    const groupAndSortTrades = (trades) => {
        const grouped = {};
        trades.forEach((trade) => {
            const key = `${trade.ticker}-${new Date(trade.created_time).toISOString()}`;
            if (!grouped[key]) {
                grouped[key] = {
                    ticker: trade.ticker,
                    created_time: trade.created_time,
                    yesTrades: [],
                    noTrades: [],
                };
            }

            if (trade.taker_side === "yes") {
                grouped[key].yesTrades.push(trade);
            } else {
                grouped[key].noTrades.push(trade);
            }
        });

        Object.values(grouped).forEach((group) => {
            group.yesTrades.sort((a, b) => a.yes_price - b.yes_price);
            group.noTrades.sort((a, b) => a.no_price - b.no_price);
        });

        return Object.values(grouped);
    };

    const formatTrades = (trades) => {
        return trades.map((trade, index) => {
            const sideEmoji = trade.taker_side === "yes" ? "✅" : "❌";
            const price = trade.taker_side === "yes" ? trade.yes_price : trade.no_price;
            const mmTrade = trade.is_mm_trade ? (
                <img
                    src="/kt.png"
                    alt="MM Trade"
                    title="MM Trade"
                    style={{ width: '20px', height: '20px', verticalAlign: 'middle' }}
                />
            ) : "";
            const mmTaker = trade.mm_is_taker ? (
                <img
                    src="/kttaker.png"
                    alt="MM Trade"
                    title="MM Trade"
                    style={{ width: '20px', height: '20px', verticalAlign: 'middle' }}
                />
            ) : "";

            return (
                <div key={index} className="trade">
                    {sideEmoji} <strong>{trade.count}</strong> {trade.taker_side.toUpperCase()}{" "}
                    @ <strong>{price}</strong> [{trade.yes_price} | {trade.no_price}]{" "}
                    {mmTrade} {mmTaker}
                </div>
            );
        });
    };

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", second: "2-digit" });
    };

    return (
        <div className="container">
            <header>
                <h1>Trades Viewer {instanceId}</h1>
                <div className="controls">
                    <input
                        type="text"
                        placeholder="Ticker Filter (Hit Enter)"
                        style={{ border: '1px solid #ccc', padding: '8px', borderRadius: '4px' }}
                        defaultValue={tickerFilter}
                        onBlur={(e) => setTickerFilter(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                setTickerFilter(e.target.value);
                            }
                        }}
                    />
                    <select
                        value={traderFilter}
                        style={{ border: '1px solid #ccc', padding: '8px', borderRadius: '4px' }}
                        onChange={(e) => setTraderFilter(e.target.value)}
                    >
                        <option value="">all traders</option>
                        <option value="adhi">adhi</option>
                        <option value="jason">jason</option>
                        <option value="luis">luis</option>
                        <option value="richard">richard</option>
                        <option value="zengyin">zengyin</option>
                    </select>
                    <input
                        type="number"
                        placeholder="Max trades"
                        value={maxTrades}
                        style={{ border: '1px solid #ccc', padding: '8px', borderRadius: '4px' }}
                        onChange={(e) => setMaxTrades(Number(e.target.value))}
                        min="1"
                    />
                    <button
                        onClick={() => setIsAutoRefresh(!isAutoRefresh)}
                        style={{
                            backgroundColor: isAutoRefresh ? "green" : "red",
                            color: "white",
                            border: "none",
                            borderRadius: "4px",
                            padding: "5px 10px", // Optional: Adjust padding for better look
                            cursor: "pointer",
                            fontSize: "12px", // Smaller font size for compactness
                        }}
                        title={isAutoRefresh ? "Auto-Refresh On" : "Auto-Refresh Off"}
                    >
                        {isAutoRefresh ? "Refresh On" : "Refresh Off"}
                    </button>
                    <span className={`status ${status.toLowerCase()}`}>{status}</span>
                </div>
            </header>
            <main className="slack-feed">
                {trades.map((group, index) => (
                    <div key={index} className="slack-message">
                        <div className="trade-header">
                            <span className="trade-ticker">{`${group.ticker}`}</span>
                            <small
                                className="trade-timestamp"
                                title={new Date(group.created_time).toLocaleString()}
                                style={{ marginLeft: 'auto' }}
                            >
                                {formatTimestamp(group.created_time)}
                            </small>
                        </div>
                        <div className="trade-body">
                            <div className="yes-trades">{formatTrades(group.yesTrades)}</div>
                            <div className="no-trades">{formatTrades(group.noTrades)}</div>
                        </div>
                    </div>
                ))}
            </main>
        </div>
    );
};


const TradesViewerContainer = () => {
    const [instances, setInstances] = useState([1]);
    const [fontSize, setFontSize] = useState(() => {
        const savedFontSize = localStorage.getItem("fontSize");
        return savedFontSize ? parseInt(savedFontSize, 10) : 12;
    });

    const addInstance = () => {
        setInstances([...instances, instances.length + 1]);
    };

    const deleteInstance = (id) => {
        setInstances(instances.filter((instanceId) => instanceId !== id));
    };

    const increaseFontSize = () => {
        setFontSize((prev) => {
            const newSize = Math.min(prev + 1, 20);
            localStorage.setItem("fontSize", newSize);
            return newSize;
        });
    };

    const decreaseFontSize = () => {
        setFontSize((prev) => {
            const newSize = Math.max(prev - 1, 8);
            localStorage.setItem("fontSize", newSize);
            return newSize;
        });
    };

    useEffect(() => {
        document.documentElement.style.setProperty("--base-font-size", `${fontSize}px`);
    }, [fontSize]);

    return (
        <Box sx={{ padding: 0 }}>
            <header>
                <h1>Trades Viewer</h1>
                <div style={{ marginBottom: "10px", display: "flex", alignItems: "center", gap: "10px" }}>
                    <button
                        onClick={decreaseFontSize}
                        style={{
                            padding: "5px 10px",
                            fontSize: "14px",
                            borderRadius: "4px",
                            cursor: "pointer",
                        }}
                    >
                        -
                    </button>
                    <span style={{ fontSize: "14px" }}>{fontSize}px</span>
                    <button
                        onClick={increaseFontSize}
                        style={{
                            padding: "5px 10px",
                            fontSize: "14px",
                            borderRadius: "4px",
                            cursor: "pointer",
                        }}
                    >
                        +
                    </button>
                </div>
            </header>
            <Grid container spacing={0.5}>
                {instances.map((id) => (
                    <Grid item xs={12} sm={6} md={4} key={id}>
                        <Box
                            sx={{
                                border: "1px solid #ddd",
                                borderRadius: 1,
                                padding: 1,
                                position: "relative",
                                overflow: "hidden",
                                width: "100%",
                                boxSizing: "border-box",
                            }}
                        >
                            <IconButton
                                onClick={addInstance}
                                color="success"
                                size="small"
                                sx={{
                                    position: "absolute",
                                    top: 4,
                                    left: 4,
                                    zIndex: 10,
                                    width: 16,
                                    height: 16,
                                }}
                            >
                                <AddCircleIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                                onClick={() => deleteInstance(id)}
                                color="error"
                                size="small"
                                sx={{
                                    position: "absolute",
                                    top: 4,
                                    right: 4,
                                    zIndex: 10,
                                    width: 16,
                                    height: 16,
                                }}
                            >
                                <RemoveCircleIcon fontSize="small" />
                            </IconButton>
                            <TradesViewer instanceId={id} />
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default TradesViewerContainer;