import React, { useState, useRef } from 'react';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { EditorProps } from './types';

interface TextEditorProps extends EditorProps {
    allowEmptyString?: boolean;
    enforcePostgresArrayFormat?: boolean;
    enforceJsonArrayFormat?: boolean;
}

const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
        height: '100%',
        color: 'inherit',
        fontSize: 'inherit',
        lineHeight: 'inherit',
    },
    '& .MuiInputBase-input': {
        padding: '2px',
        height: '100%',
        color: 'inherit',
        textAlign: 'left',
        fontSize: 'inherit',
        '&::placeholder': {
            textAlign: 'left',
            fontSize: 'inherit',
            opacity: 0.7,
        },
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'inherit',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'inherit',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main,
    },
}));

const isValidJsonArray = (str: string): boolean => {
    try {
        const parsed = JSON.parse(str);
        return Array.isArray(parsed);
    } catch {
        return false;
    }
};

const TextEditor: React.FC<TextEditorProps> = ({
    column,
    row,
    onRowChange,
    onClose,
    allowEmptyString = true,
    enforcePostgresArrayFormat = false,
    enforceJsonArrayFormat = false,
}) => {
    const initialValue = row[column.key];
    const [value, setValue] = useState(initialValue);
    const [isValid, setIsValid] = useState(true);
    const lastValidValue = useRef(row[column.key]);

    const validate = (input: string): boolean => {
        if (allowEmptyString && input.trim() === '') return true;
        if (enforceJsonArrayFormat) return isValidJsonArray(input);
        return true;
    };

    const formatInput = (input: string): string => {
        if (enforceJsonArrayFormat) {
            try {
                const parsed = JSON.parse(input);
                if (Array.isArray(parsed)) {
                    // Only stringify if the input is not already in valid JSON array format
                    return JSON.stringify(parsed);
                }
            } catch {
                // Ignore invalid JSON, return the input as-is
            }
        }
        return input;
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;

        // Live update the raw input value
        setValue(inputValue);

        if (enforceJsonArrayFormat) {
            if (isValidJsonArray(inputValue)) {
                // Parse and format once
                const formattedValue = formatInput(inputValue);
                setValue(formattedValue); // Display the formatted value
                onRowChange({ ...row, [column.key]: formattedValue }); // Pass formatted value to parent
                lastValidValue.current = formattedValue;
                setIsValid(true);
            } else {
                setIsValid(false);
            }
        } else {
            onRowChange({ ...row, [column.key]: inputValue });
            lastValidValue.current = inputValue;
            setIsValid(true);
        }
    };


    return (
        <StyledTextField
            type="text"
            autoFocus
            value={value}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            error={!isValid}
            helperText={
                !isValid && enforceJsonArrayFormat
                    ? "Invalid JSON array format (e.g., [\"value1\", \"value2\"])"
                    : ""
            }
            inputProps={{
                style: { textAlign: 'left' },
                placeholder: enforceJsonArrayFormat ? '["value1", "value2", ...]' : undefined,
            }}
        />
    );
};

export default TextEditor;
